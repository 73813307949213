import { Component, OnInit, AfterViewInit, Inject, ChangeDetectorRef } from '@angular/core';
import { KIDSessionQuery } from 'src/app/k-id-session/k-id-session.query';
import { Observable } from 'rxjs';
import { KIDSessionState } from 'src/app/k-id-session/k-id-session.store';
import { OverlayDialogService } from '../../overlay-dialog/overlay-dialog.service';
import { environment } from 'src/environments/environment';
import { UntypedFormBuilder } from '@angular/forms';
import { WindowService } from 'src/app/window-service/window.service';
import { isEmpty } from 'lodash';
import { LoaderService } from 'src/app/loader/loader.service';
import { ActiveTransactionService, ActiveTransaction } from '../charge-point/active-transaction/active-transaction.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BlueCommerceService } from 'src/app/blue-commerce/blue-commerce.service';
import { InvoiceService } from '../invoice/invoice.service';
import { Invoice } from '../invoice/invoice.model';
import { InvoiceQuery } from '../invoice/invoice.query';
import { KChargeProfileService } from 'src/app/k-charge-profile/k-charge-profile.service';
import { DiscountQuery } from '../discounts/state/discount.query';
import { Discount } from '../discounts/state/discount.model';
import { DiscountService } from '../discounts/state/discount.service';
import * as moment from 'moment';
import { ErrorsService } from 'src/app/errors-handler/errors.service';
import { AppVersionService } from 'src/app/services/app-version.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/language/language.service';


@Component({
  selector: 'kc-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  user$: Observable<KIDSessionState>;
  loggedIn$: Observable<boolean>;
  environment = environment;
  paymentInitDataFailed = false;
  pspUrl: string;
  monthlyInvoices$: Observable<Invoice[]>;
  activeTransactions: ActiveTransaction[] = [];
  isAddCardDisabled = true;
  private activeTransacionsInited = false;
  device$ = this.windowService.deviceTarget$;
  lang: string;

  discounts$ = this.discountQuery.selectAll();

  constructor(@Inject('Window') window: Window,
    private kIDSessionQuery: KIDSessionQuery,
    private activeTransactionService: ActiveTransactionService,
    private overlayDialogService: OverlayDialogService,
    public formBuilder: UntypedFormBuilder,
    public windowService: WindowService,
    private loaderService: LoaderService,
    private blueCommerceService: BlueCommerceService,
    private router: Router,
    private invoiceService: InvoiceService,
    private invoiceQuery: InvoiceQuery,
    private kChargeProfileService: KChargeProfileService,
    private discountQuery: DiscountQuery,
    private discountService: DiscountService,
    private route: ActivatedRoute,
    private appVersionService: AppVersionService,
    private translateService: TranslateService,
    private languageService: LanguageService) { }

  ngOnInit() {
    this.checkAppType();
    this.lang = this.languageService.locale;

    this.loaderService.activateSpinner('user');
    this.user$ = this.kIDSessionQuery.user$;
    this.loggedIn$ = this.kIDSessionQuery.isLoggedIn$;
    this.activeTransactionService.activeTransaction$.subscribe(activeTransactions => {
      this.activeTransactions = activeTransactions;
    });
    this.user$.subscribe(user => {
      if (!user || !user.name) {
        this.isAddCardDisabled = true;
      } else {
        this.isAddCardDisabled = false;
      }
      this.loaderService.deactivateSpinner('user');
      if (user && user.email) {
        if (!this.activeTransacionsInited) {
          this.invoiceService.getMonthlyInvoices();
          this.monthlyInvoices$ = this.invoiceQuery.unpaidInvoices$;
          this.activeTransactionService.getActiveTransaction();
          this.activeTransacionsInited = true;
        }
        this.discountService.get().subscribe(() => {});
      } else {
        this.activeTransacionsInited = false;
        this.paymentInitDataFailed = true;
      }
    });
  }

  /**
   * Checks if 'appType' query param is found and sets type based on it.
   */
  private checkAppType() {
    this.route.queryParams.pipe().subscribe(res => {
      const type = res['appType'];
      if (type) {
        switch (type) {
          case 'ios': {
            this.appVersionService.setAsNativeIOS();
            break;
          }
          case 'android': {
            this.appVersionService.setAsNativeAndroid();
            break;
          }
        }
      }
    });
  }

  /**
   * Logout K-ID session
   */
  logOut() {
    this.kChargeProfileService.logout();
  }

  /**
   * Open selected component as overlay dialog
   */
  openOverlayDialog(component, data?) {
    this.overlayDialogService.openOverlayDialog(component, data);
  }

  getCardExpiry(card: any) {
    const expiryYear = card.expectedValidity.substring(card.expectedValidity.length - 4);
    const expiryMonth = card.expectedValidity.split(expiryYear)[0];
    const expiry = `${expiryMonth}/${expiryYear}`;
    return expiry;

  }

  /**
   * Opens K-ID widget for login/logout and navigation on desktop and tablet
   */
  openWidget() {
    this.kChargeProfileService.login();
  }

  editUser() {
    this.overlayDialogService.openOverlayDialog('EditUserComponent', { updateName: true });
  }

  /**
   * Checks if object exist and is it empty
   * @param object
   */
  checkObject(object) {

    if (object) {
      return !isEmpty(object);
    } else {
      return false;
    }
  }

  userHasContactInfo(contactInfo) {
    if (contactInfo.phoneNumber && contactInfo.phoneNumber !== '' &&
      contactInfo.streetAddress && contactInfo.streetAddress !== '' &&
      contactInfo.postalCode && contactInfo.postalCode !== '' &&
      contactInfo.city && contactInfo.city !== '') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Open router container
   */
  openRouteContainer() {
    this.windowService.setRouteContainerOpenState(true);
  }

  /**
   * Navigates to Bluecommerce's add card page.
   * Fetches required init data and psp url before navigation.
   * Creates a hidden form to prevent caching.
   */
  navigateToAddCard() {
    this.isAddCardDisabled = true;
    this.blueCommerceService.getSaveCardInitData(this.router.routerState.snapshot.url).subscribe(response => {
      const paymentInitData = response['data'];
      const pspUrl = response['url'];

      const form = document.createElement('form');
      form.setAttribute('style', 'visibility: hidden;');

      form.method = 'POST';
      form.action = pspUrl;

      for (const obj of paymentInitData) {
        const elem = document.createElement('input');
        elem.name = obj.key;
        elem.value = obj.value;
        form.appendChild(elem);
      }

      document.body.appendChild(form);

      form.submit();
    }, err => {
      this.paymentInitDataFailed = true;
      this.isAddCardDisabled = false;
    });
  }

  /**
   * Disables addCard button. Prevents an error caused if user clicks the button multiple times.
   */
  disableAddCard() {
    this.isAddCardDisabled = true;
  }

  addDiscount() {
    this.overlayDialogService.openOverlayDialog('AddDiscountDialogComponent');
  }

  navigateToDiscount(discount: Discount) {
    this.overlayDialogService.openOverlayDialog('DiscountDialogComponent', discount);
  }

  navigateToInvoicingAgreement() {
    this.overlayDialogService.openOverlayDialog('InvoicingAgreementDialogComponent');
  }

  formatTime(time: string) {
    if (localStorage.getItem('language') === 'en') {
      return moment(time).format('MM.DD.YYYY');
    } else {
      return moment(time).format('DD.MM.YYYY');
    }
  }

  deletePaymentCard(user) {
    if (this.isAddCardDisabled) {
      this.openOverlayDialog('CantRemovePaymentCardComponent');
    } else {
      this.openOverlayDialog('DeletePaymentCardComponent', user['paymentCard']);
    }
  }

  onLangChange(event: string) {
    this.translateService.use(event);
    this.languageService.registerCulture(event);
  }
}
