export const cities = [
    {
      "city": "Helsinki"
    },
    {
      "city": "Tampere"
    },
    {
      "city": "Espoo"
    },
    {
      "city": "Turku"
    },
    {
      "city": "Vantaa"
    },
    {
      "city": "Oulu"
    },
    {
      "city": "Jyväskylä"
    },
    {
      "city": "Kuopio"
    },
    {
      "city": "Lahti"
    },
    {
      "city": "Kouvola"
    },
    {
      "city": "Pori"
    },
    {
      "city": "Joensuu"
    },
    {
      "city": "Lappeenranta"
    },
    {
      "city": "Hämeenlinna"
    },
    {
      "city": "Vaasa"
    },
    {
      "city": "Seinäjoki"
    },
    {
      "city": "Rovaniemi"
    },
    {
      "city": "Mikkeli"
    },
    {
      "city": "Kotka"
    },
    {
      "city": "Salo"
    },
    {
      "city": "Porvoo"
    },
    {
      "city": "Kokkola"
    },
    {
      "city": "Lohja"
    },
    {
      "city": "Hyvinkää"
    },
    {
      "city": "Nurmijärvi"
    },
    {
      "city": "Järvenpää"
    },
    {
      "city": "Rauma"
    },
    {
      "city": "Kirkkonummi"
    },
    {
      "city": "Tuusula"
    },
    {
      "city": "Kajaani"
    },
    {
      "city": "Savonlinna"
    },
    {
      "city": "Kerava"
    },
    {
      "city": "Nokia"
    },
    {
      "city": "Kaarina"
    },
    {
      "city": "Ylöjärvi"
    },
    {
      "city": "Kangasala"
    },
    {
      "city": "Jyväskylän Maalaiskunta"
    },
    {
      "city": "Riihimäki"
    },
    {
      "city": "Vihti"
    },
    {
      "city": "Raseborg"
    },
    {
      "city": "Imatra"
    },
    {
      "city": "Sastamala"
    },
    {
      "city": "Raahe"
    },
    {
      "city": "Raisio"
    },
    {
      "city": "Hollola"
    },
    {
      "city": "Karhula"
    },
    {
      "city": "Lempäälä"
    },
    {
      "city": "Iisalmi"
    },
    {
      "city": "Tornio"
    },
    {
      "city": "Siilinjärvi"
    },
    {
      "city": "Kemi"
    },
    {
      "city": "Kurikka"
    },
    {
      "city": "Jämsä"
    },
    {
      "city": "Varkaus"
    },
    {
      "city": "Klaukkala"
    },
    {
      "city": "Valkeakoski"
    },
    {
      "city": "Mäntsälä"
    },
    {
      "city": "Äänekoski"
    },
    {
      "city": "Hamina"
    },
    {
      "city": "Kuusankoski"
    },
    {
      "city": "Lieto"
    },
    {
      "city": "Heinola"
    },
    {
      "city": "Jakobstad"
    },
    {
      "city": "Sipoo"
    },
    {
      "city": "Korsholm"
    },
    {
      "city": "Naantali"
    },
    {
      "city": "Haukipudas"
    },
    {
      "city": "Laukaa"
    },
    {
      "city": "Pieksämäki"
    },
    {
      "city": "Kempele"
    },
    {
      "city": "Forssa"
    },
    {
      "city": "Janakkala"
    },
    {
      "city": "Kauhava"
    },
    {
      "city": "Loimaa"
    },
    {
      "city": "Orimattila"
    },
    {
      "city": "Uusikaupunki"
    },
    {
      "city": "Loviisa"
    },
    {
      "city": "Sippola"
    },
    {
      "city": "Vammala"
    },
    {
      "city": "Ylivieska"
    },
    {
      "city": "Nastola"
    },
    {
      "city": "Kontiolahti"
    },
    {
      "city": "Ekenäs"
    },
    {
      "city": "Lapua"
    },
    {
      "city": "Kauhajoki"
    },
    {
      "city": "Kiiminki"
    },
    {
      "city": "Ulvila"
    },
    {
      "city": "Lauritsala"
    },
    {
      "city": "Kalajoki"
    },
    {
      "city": "Pargas"
    },
    {
      "city": "Ilmajoki"
    },
    {
      "city": "Liperi"
    },
    {
      "city": "Eura"
    },
    {
      "city": "Alavus"
    },
    {
      "city": "Mikkelin Maalaiskunta"
    },
    {
      "city": "Vehkalahti"
    },
    {
      "city": "Kankaanpää"
    },
    {
      "city": "Mariehamn"
    },
    {
      "city": "Valkeala"
    },
    {
      "city": "Pedersöre"
    },
    {
      "city": "Nivala"
    },
    {
      "city": "Kitee"
    },
    {
      "city": "Nurmo"
    },
    {
      "city": "Joutseno"
    },
    {
      "city": "Hämeenkyrö"
    },
    {
      "city": "Kivistö"
    },
    {
      "city": "Paimio"
    },
    {
      "city": "Huittinen"
    },
    {
      "city": "Liminka"
    },
    {
      "city": "Keuruu"
    },
    {
      "city": "Muurame"
    },
    {
      "city": "Alajärvi"
    },
    {
      "city": "Lapinlahti"
    },
    {
      "city": "Ii"
    },
    {
      "city": "Leppävirta"
    },
    {
      "city": "Saarijärvi"
    },
    {
      "city": "Oulunsalo"
    },
    {
      "city": "Hattula"
    },
    {
      "city": "Masku"
    },
    {
      "city": "Kauniainen"
    },
    {
      "city": "Eurajoki"
    },
    {
      "city": "Hakkila"
    },
    {
      "city": "Orivesi"
    },
    {
      "city": "Närpes"
    },
    {
      "city": "Somero"
    },
    {
      "city": "Muhos"
    },
    {
      "city": "Karkkila"
    },
    {
      "city": "Halikko"
    },
    {
      "city": "Hanko"
    },
    {
      "city": "Karis"
    },
    {
      "city": "Hausjärvi"
    },
    {
      "city": "Kiuruvesi"
    },
    {
      "city": "Pöytyä"
    },
    {
      "city": "Laitila"
    },
    {
      "city": "Keminmaa"
    },
    {
      "city": "Asikkala"
    },
    {
      "city": "Elimäki"
    },
    {
      "city": "Loppi"
    },
    {
      "city": "Laihia"
    },
    {
      "city": "Toijala"
    },
    {
      "city": "Nurmes"
    },
    {
      "city": "Jalasjärvi"
    },
    {
      "city": "Mynämäki"
    },
    {
      "city": "Oulainen"
    },
    {
      "city": "Kokemäki"
    },
    {
      "city": "Nykarleby"
    },
    {
      "city": "Haapajärvi"
    },
    {
      "city": "Suonenjoki"
    },
    {
      "city": "Jämsänkoski"
    },
    {
      "city": "Tyrvää"
    },
    {
      "city": "Harjavalta"
    },
    {
      "city": "Pyhäselkä"
    },
    {
      "city": "Ikaalinen"
    },
    {
      "city": "Haapavesi"
    },
    {
      "city": "Outokumpu"
    },
    {
      "city": "Virrat"
    },
    {
      "city": "Iitti"
    },
    {
      "city": "Tyrnävä"
    },
    {
      "city": "Kristinestad"
    },
    {
      "city": "Parkano"
    },
    {
      "city": "Vörå"
    },
    {
      "city": "Kronoby"
    },
    {
      "city": "Pälkäne"
    },
    {
      "city": "Viitasaari"
    },
    {
      "city": "Eno"
    },
    {
      "city": "Vanaja"
    },
    {
      "city": "Rajamäki"
    },
    {
      "city": "Juva"
    },
    {
      "city": "Piikkiö"
    },
    {
      "city": "Nilsiä"
    },
    {
      "city": "Mänttä"
    },
    {
      "city": "Tammela"
    },
    {
      "city": "Siuntio"
    },
    {
      "city": "Mäntyharju"
    },
    {
      "city": "Pieksämäen Maalaiskunta"
    },
    {
      "city": "Rusko"
    },
    {
      "city": "Noormarkku"
    },
    {
      "city": "Heinolan Maalaiskunta"
    },
    {
      "city": "Pattijoki"
    },
    {
      "city": "Ähtäri"
    },
    {
      "city": "Saloinen"
    },
    {
      "city": "Loimaan Kunta"
    },
    {
      "city": "Perniö"
    },
    {
      "city": "Nakkila"
    },
    {
      "city": "Kangasniemi"
    },
    {
      "city": "Kerimäki"
    },
    {
      "city": "Malax"
    },
    {
      "city": "Ylistaro"
    },
    {
      "city": "Ingå"
    },
    {
      "city": "Kannus"
    },
    {
      "city": "Lammi"
    },
    {
      "city": "Pyhäjärvi"
    },
    {
      "city": "Teuva"
    },
    {
      "city": "Vilppula"
    },
    {
      "city": "Jokioinen"
    },
    {
      "city": "Suolahti"
    },
    {
      "city": "Pyhtää"
    },
    {
      "city": "Ruokolahti"
    },
    {
      "city": "Larsmo"
    },
    {
      "city": "Hankasalmi"
    },
    {
      "city": "Parikkala"
    },
    {
      "city": "Oulujoki"
    },
    {
      "city": "Dickursby"
    },
    {
      "city": "Viiala"
    },
    {
      "city": "Pornainen"
    },
    {
      "city": "Sievi"
    },
    {
      "city": "Joroinen"
    },
    {
      "city": "Askola"
    },
    {
      "city": "Kellokoski"
    },
    {
      "city": "Korpilahti"
    },
    {
      "city": "Pojo"
    },
    {
      "city": "Luumäki"
    },
    {
      "city": "Nousiainen"
    },
    {
      "city": "Ristiina"
    },
    {
      "city": "Äetsä"
    },
    {
      "city": "Urjala"
    },
    {
      "city": "Taipalsaari"
    },
    {
      "city": "Juankoski"
    },
    {
      "city": "Isokyrö"
    },
    {
      "city": "Vähäkyrö"
    },
    {
      "city": "Alahärmä"
    },
    {
      "city": "Joutsa"
    },
    {
      "city": "Jomala"
    },
    {
      "city": "Ruovesi"
    },
    {
      "city": "Kärkölä"
    },
    {
      "city": "Gladas"
    },
    {
      "city": "Polvijärvi"
    },
    {
      "city": "Vesilahti"
    },
    {
      "city": "Ruukki"
    },
    {
      "city": "Kälviä"
    },
    {
      "city": "Sääksmäki"
    },
    {
      "city": "Jurva"
    },
    {
      "city": "Vanhakylä"
    },
    {
      "city": "Kaustinen"
    },
    {
      "city": "Sysmä"
    },
    {
      "city": "Petäjävesi"
    },
    {
      "city": "Aura"
    },
    {
      "city": "Hauho"
    },
    {
      "city": "Koria"
    },
    {
      "city": "Maaninka"
    },
    {
      "city": "Uurainen"
    },
    {
      "city": "Rantasalmi"
    },
    {
      "city": "Kuortane"
    },
    {
      "city": "Muuruvesi"
    },
    {
      "city": "Pertteli"
    },
    {
      "city": "Seinäjoki"
    },
    {
      "city": "Savitaipale"
    },
    {
      "city": "Rautjärvi"
    },
    {
      "city": "Karttula"
    },
    {
      "city": "Kalvola"
    },
    {
      "city": "Luvia"
    },
    {
      "city": "Virolahti"
    },
    {
      "city": "Kiukainen"
    },
    {
      "city": "Dragsfjärd"
    },
    {
      "city": "Toholampi"
    },
    {
      "city": "Rautalampi"
    },
    {
      "city": "Veteli"
    },
    {
      "city": "Kimito"
    },
    {
      "city": "Lappi"
    },
    {
      "city": "Lappajärvi"
    },
    {
      "city": "Pyhäjoki"
    },
    {
      "city": "Kaavi"
    },
    {
      "city": "Merikarvia"
    },
    {
      "city": "Töysä"
    },
    {
      "city": "Padasjoki"
    },
    {
      "city": "Uskela"
    },
    {
      "city": "Maaria"
    },
    {
      "city": "Lemi"
    },
    {
      "city": "Vimpeli"
    },
    {
      "city": "Vihanti"
    },
    {
      "city": "Punkalaidun"
    },
    {
      "city": "Himanka"
    },
    {
      "city": "Sauvo"
    },
    {
      "city": "Hartola"
    },
    {
      "city": "Keikyä"
    },
    {
      "city": "Ylihärmä"
    },
    {
      "city": "Varpaisjärvi"
    },
    {
      "city": "Alastaro"
    },
    {
      "city": "Nummi"
    },
    {
      "city": "Ruotsinpyhtää"
    },
    {
      "city": "Mouhijärvi"
    },
    {
      "city": "Reisjärvi"
    },
    {
      "city": "Lohtaja"
    },
    {
      "city": "Kuorevesi"
    },
    {
      "city": "Teisko"
    },
    {
      "city": "Lappträsk"
    },
    {
      "city": "Kuru"
    },
    {
      "city": "Konnevesi"
    },
    {
      "city": "Alavieska"
    },
    {
      "city": "Köyliö"
    },
    {
      "city": "Kiihtelysvaara"
    },
    {
      "city": "Vuolijoki"
    },
    {
      "city": "Kylmäkoski"
    },
    {
      "city": "Karkku"
    },
    {
      "city": "Tuusniemi"
    },
    {
      "city": "Översundom"
    },
    {
      "city": "Finström"
    },
    {
      "city": "Evijärvi"
    },
    {
      "city": "Karvia"
    },
    {
      "city": "Toivakka"
    },
    {
      "city": "Karinainen"
    },
    {
      "city": "Ypäjä"
    },
    {
      "city": "Humppila"
    },
    {
      "city": "Keitele"
    },
    {
      "city": "Koski Tl"
    },
    {
      "city": "Rääkkylä"
    },
    {
      "city": "Pomarkku"
    },
    {
      "city": "Korsnäs"
    },
    {
      "city": "Vesanto"
    },
    {
      "city": "Pyhäranta"
    },
    {
      "city": "Hämeenkoski"
    },
    {
      "city": "Miehikkälä"
    },
    {
      "city": "Lumijoki"
    },
    {
      "city": "Kihniö"
    },
    {
      "city": "Marttila"
    },
    {
      "city": "Lemland"
    },
    {
      "city": "Pukkila"
    },
    {
      "city": "Myrskylä"
    },
    {
      "city": "Tarvasjoki"
    },
    {
      "city": "Jämijärvi"
    },
    {
      "city": "Lavia"
    },
    {
      "city": "Honkajoki"
    },
    {
      "city": "Taivassalo"
    },
    {
      "city": "Tervo"
    },
    {
      "city": "Hammarland"
    },
    {
      "city": "Enonkoski"
    },
    {
      "city": "Oripää"
    },
    {
      "city": "Karijoki"
    },
    {
      "city": "Kaskinen"
    },
    {
      "city": "Merijärvi"
    },
    {
      "city": "Hailuoto"
    },
    {
      "city": "Eckerö"
    },
    {
      "city": "Föglö"
    },
    {
      "city": "Geta"
    },
    {
      "city": "Vårdö"
    },
    {
      "city": "Nummela"
    },
    {
      "city": "Turenki"
    },
    {
      "city": "Parola"
    },
    {
      "city": "Oitti"
    },
    {
      "city": "Godby"
    },
    {
      "city": "Juva"
    },
    {
      "city": "Järvelä"
    },
    {
      "city": "Siltakylä"
    },
    {
      "city": "Vääksy"
    },
    {
      "city": "Bennäs"
    },
    {
      "city": "Ödkarby"
    },
    {
      "city": "Kyrö"
    },
    {
      "city": "Kausala"
    },
    {
      "city": "Vinkkilä"
    },
    {
      "city": "Dalsbruk"
    },
    {
      "city": "Simpele"
    },
    {
      "city": "Björby"
    },
    {
      "city": "Korkeakoski"
    },
    {
      "city": "Taavetti"
    },
    {
      "city": "Åva"
    },
    {
      "city": "Karlby"
    },
    {
      "city": "Pyhäsalmi"
    }
  ]