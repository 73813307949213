<div class="socketPrice__wrapper">
  <ng-container *ngIf="schedules">
    <div class="schedulesWrapper">
      <div class="schedules">
        <kc-schedule-item [active]="true" [price]="price"></kc-schedule-item>
        <kc-schedule-item [active]="false" [price]="getOtherSchedule()"></kc-schedule-item>
      </div>
      <div class="viewPrices">
        <span (click)="openPricingDialog()">{{ 'viewPrices' | translate }}</span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="price; else loadingTemplate">
    <ng-container *ngIf="price.active && price.active === true; else freeChargingTpl">
      <kc-socket-price-item *ngIf="price.energy" [title]="('basePrice' | translate)"
        [subtitle]="'appliesDuringCharging' | translate" [price]="price.energy" [pricingPrinciple]="'kWh'">
      </kc-socket-price-item>
      <kc-socket-price-item *ngIf="price.duration" [title]="getTimePriceTitle()" [subtitle]="getTimePriceSubtitle()"
        [plusSign]="price.energy" [price]="price.duration" [pricingPrinciple]="getTimePricePrinciple()">
      </kc-socket-price-item>
      <!-- <kc-socket-price-item *ngIf="!isLoggedIn" [title]="'startingPrice' | translate"
        [subtitle]="'notAppliedToRegistered' | translate" [plusSign]="true" [price]="'1'"
        [pricingPrinciple]="'chargingTransaction' | translate"></kc-socket-price-item> -->
    </ng-container>
  </ng-container>
</div>

<ng-template #freeChargingTpl>
  <ng-container *ngIf="price && price.active == false; else loadingTemplate">
    <kc-socket-price-item [title]="'freeCharging' | translate" [subtitle]="'freeChargingSubtitle' | translate"
    [plusSign]="false" [price]="'0'" [pricingPrinciple]="''"></kc-socket-price-item>
  </ng-container>
</ng-template>

<ng-template #loadingTemplate>
  <kc-local-loader [message]="'loadingPrices'"></kc-local-loader>
</ng-template>

