<ng-container>
  <kc-navigation-bar></kc-navigation-bar>
  <ng-container *ngIf="(loggedIn$ | async) as loggedIn; else notLoggedInTpl">
    <div *ngIf="(user$ | async) as user; else loading" class="routerContainerContent__container">


      <ng-container *ngIf="(monthlyInvoices$ | async) as monthlyInvoices; else loadingTemplate">
        <ng-container *ngFor="let monthlyInvoice of monthlyInvoices">
          <kc-invoice-balance [monthlyInvoice]="monthlyInvoice"></kc-invoice-balance>
        </ng-container>
      </ng-container>
      <ng-template #loadingTemplate>LATAAAA...</ng-template>
      <!-- K-ID Profile -->
      <div class="routerContainerContent__subHeader">
        <span> {{ 'kid' | translate | uppercase }} </span>
        <a (click)="editUser()">{{'edit' | translate }}</a>
        <!-- <a href="{{ environment.K_ID.PROFILE_URL }}" target="_blank">{{'edit' | translate }} • K-Tunnus.fi</a> -->
      </div>
      <div class="routerContainerContent__infoContainer">
        <img src="../../../assets/icons/png/icon-profile@2x.png" alt="">
        <div class="routerContainerContent__infoContainerTextContainer">
          <span class="title">{{user.name}}</span>
          <span class="text">{{user.email}}</span>
          <span class="text" *ngIf="user['plussaCardNumber']">{{ 'plussaCard' | translate}}:
            {{ user.plussaCardNumber }}</span>
          <span class="text" *ngIf="!user['plussaCardNumber']">{{ 'plussaCard' | translate}}:
            {{ 'notAdded' | translate}} • {{ 'addPlussaCard' | translate}}</span>
        </div>
      </div>

      <!-- Address -->
      <div class="routerContainerContent__subHeader">
        <span>{{'contactInfo' | translate | uppercase }}</span>
        <a (click)="openOverlayDialog('EditUserComponent')">{{'edit' | translate }}</a>
      </div>
      <div class="routerContainerContent__infoContainer">
        <img
          [src]="(checkObject(user['contactInfo']) && userHasContactInfo(user['contactInfo'])) ? '../../../assets/icons/png/icon-contact@2x.png' : '../../../assets/icons/png/icon-contact-grey@2x.png'"
          alt="greyed if not logged in">
        <div *ngIf="(checkObject(user['contactInfo']) && userHasContactInfo(user['contactInfo']))"
          class="routerContainerContent__infoContainerTextContainer">
          <span class="text">{{user.contactInfo.phoneNumber}}</span>
          <span class="text" *ngIf="user.contactInfo.co">c/o: {{user.contactInfo.co}}</span>
          <span class="text">{{user.contactInfo.streetAddress}}</span>
          <span class="text">{{user.contactInfo.postalCode}} {{user.contactInfo?.city}}</span>
          <a href="https://tietosuoja.kesko.fi/category/k_auto_asiakkaat" class="link" target="_blank">{{ 'privacy' | translate}}</a>
          <a></a>
        </div>
        <div *ngIf="(!checkObject(user['contactInfo']) || !userHasContactInfo(user['contactInfo']))"
          class="routerContainerContent__infoContainerTextContainer">
          <span class="text">{{ 'contactInfoText' | translate }}</span>
        </div>
      </div>

      <!-- Tags -->
      <div class="routerContainerContent__subHeader">
        <span>{{'tagSettings' | translate | uppercase }}</span>
        <a *ngIf="!(!checkObject(user['tags']) && user.tagOrdered)" (click)="openOverlayDialog(checkObject(user.tags) ? 'ManageTagsComponent' : 'OrderTagDialogComponent')">{{checkObject(user.tags) ? ('showTags' | translate) :
          'orderNew' | translate }}</a>
      </div>
      <div class="routerContainerContent__infoContainer">
        <img
          [src]="checkObject(user['tags']) ? '../../../assets/icons/png/icon-tag@2x.png' : '../../../assets/icons/png/icon-tag-grey@2x.png'"
          alt="grey if no tags">
        <div *ngIf="checkObject(user['tags'])">
          <div class="routerContainerContent__infoContainerTextContainer" *ngFor="let tag of user.tags">
            <span class="title">{{tag.label}}</span>
            <span class="text">{{tag.serialNumber}}</span>
          </div>
        </div>
        <div *ngIf="!checkObject(user['tags']) && !user.tagOrdered"
          class="routerContainerContent__infoContainerTextContainer">
          <span class="text">{{ 'tagInfoText' | translate }}</span>
        </div>
        <div *ngIf="!checkObject(user['tags']) && user.tagOrdered"
          class="routerContainerContent__infoContainerTextContainer">
          <span class="text">{{ 'tagOnTheWay' | translate }}</span>
        </div>
      </div>


      <!-- Payment card -->
      <ng-container *ngIf="!user.invoicingAgreement; else invoicingAgreementTpl">
        <div class="routerContainerContent__subHeader">
          <span>{{'paymentCard' | translate | uppercase }}</span>
          <button *ngIf="!checkObject(user['paymentCard'])" (click)="navigateToAddCard()"
            [ngClass]="!isAddCardDisabled ? '' : 'gray'"
            [disabled]="isAddCardDisabled">{{'addCard' | translate }}</button>
          <a *ngIf="checkObject(user['paymentCard'])" (click)="deletePaymentCard(user)"
            [ngClass]="!isAddCardDisabled ? '' : 'gray'">{{ 'deletePaymentCard' | translate }}</a>
        </div>
        <div class="routerContainerContent__infoContainer">
          <img
            [src]="checkObject(user['paymentCard']) ? '../../../assets/icons/png/icon-creditCard@2x.png' : '../../../assets/icons/png/icon-creditCard-grey@2x.png'"
            alt="">
          <div *ngIf="checkObject(user['paymentCard'])" class="routerContainerContent__infoContainerTextContainer">
            <span class="title">{{ user['paymentCard']?.paymentMethodCode | translate}}</span>
            <span class="text">{{ user['paymentCard']?.paymentMethodTitle}}</span>
            <span class="text">{{ 'expiring' | translate }} {{ getCardExpiry(user['paymentCard']) }}</span>
          </div>
          <div *ngIf="!checkObject(user['paymentCard']) && !paymentInitDataFailed"
            class="routerContainerContent__infoContainerTextContainer">
            <span class="text">{{ 'creditCardInfoText' | translate }}</span>
          </div>
          <div *ngIf="!checkObject(user['paymentCard']) && paymentInitDataFailed"
            class="routerContainerContent__infoContainerTextContainer">
            <span class="text">{{ 'paymentDataInitFailedText' | translate }}</span>
          </div>
        </div>
      </ng-container>

      <!-- Invoicing agreement, shown instead of payment card -->
      <ng-template #invoicingAgreementTpl>
        <div class="routerContainerContent__subHeader">
          <span>{{'paymentCard' | translate | uppercase }}</span>
          <button (click)="navigateToInvoicingAgreement()">{{'invoicingAgreement' | translate }}</button>
        </div>
        <div class="routerContainerContent__infoContainer">
          <img src="../../../assets/icons/png/icon-contract.png" alt="">
          <div class="routerContainerContent__infoContainerTextContainer">
            <span class="text">{{ user.invoicingAgreement.organization.name }} {{ 'invoicingAgreement' | translate | lowercase }}</span>
            <span class="text" *ngIf="user.invoicingAgreement.expirationDate; else appliesIndefinitely">{{ 'invoicingAppliesUntil' | translate }} {{ formatTime(user.invoicingAgreement.expirationDate) }} {{ 'discountValidUntil2' | translate }}</span>
          </div>
        </div>
      </ng-template>

      <ng-template #appliesIndefinitely>
        <span class="text">{{ 'appliesIndefinitely' | translate }}</span>
      </ng-template>
      

      <!-- Discounts -->
      <div class="routerContainerContent__subHeader">
        <span>{{'discounts' | translate | uppercase }}</span>
        <button (click)="addDiscount()">{{'addDiscount' | translate }}</button>
      </div>
      <div class="routerContainerContent__infoContainer -discounts"
        [ngClass]="(device$ | async) !== 'desktop' ? '' : '-desktop'">
        <ng-container *ngIf="(discounts$ | async) as discounts">
          <ng-container *ngIf="discounts.length !== 0; else noDiscounts">
            <ng-container *ngFor="let discount of discounts; let last = last">
              <kc-discount-item [discount]="discount" [lastItem]="last ? true : false" (click)="navigateToDiscount(discount)"></kc-discount-item>
            </ng-container>
          </ng-container>
        </ng-container>
        <!-- <img
          [src]="checkObject(user['paymentCard']) ? '../../../assets/icons/png/icon-creditCard@2x.png' : '../../../assets/icons/png/icon-creditCard-grey@2x.png'"
          alt="">
        <div *ngIf="checkObject(user['paymentCard'])" class="routerContainerContent__infoContainerTextContainer">
          <span class="title">{{ user['paymentCard']?.paymentMethodCode | translate}}</span>
          <span class="text">{{ user['paymentCard']?.paymentMethodTitle}}</span>
        </div>
        <div *ngIf="!checkObject(user['paymentCard']) && !paymentInitDataFailed"
          class="routerContainerContent__infoContainerTextContainer">
          <span class="text">{{ 'creditCardInfoText' | translate }}</span>
        </div>
        <div *ngIf="!checkObject(user['paymentCard']) && paymentInitDataFailed"
          class="routerContainerContent__infoContainerTextContainer">
          <span class="text">{{ 'paymentDataInitFailedText' | translate }}</span>
        </div> -->
      </div>


      <!-- APP settings -->
      <div class="routerContainerContent__subHeader">
        <span>{{'appSettings' | translate | uppercase }}</span>
        <a (click)="openOverlayDialog('EditAppSettingsComponent')">{{'edit' | translate }}</a>
      </div>
      <div class="routerContainerContent__infoContainer">
        <img src="../../../assets/icons/png/icon-wrench@2x.png" alt="">
        <div class="routerContainerContent__infoContainerTextContainer">
          <span class="text">{{'language' | translate}}:
            {{ checkObject(user.appSettings) ? (user['appSettings']['language'] | uppercase) : 'FI'}} -
            {{checkObject(user.appSettings) ? (user['appSettings']['language'] | lowercase | translate) : ('fi' | translate)}}</span>
            <span class="text">{{ (user.contactInfo?.marketing ? 'marketingAllowed' : 'marketingDenied') | translate }}</span>
          <!-- <span class="text">{{'appVersion' | translate}}: 0.5.1</span> -->
        </div>
      </div>


      <div class="routerContainerContent__supportWrapper">
        <div class="routerContainerContent__supportContainer">
          <img src="../../../assets/icons/png/support@2x.png" alt="">
          <div class="textContainer">
            <span class="header">{{'needHelp' | translate}}</span>
            <span class="infoText">{{'supportInfoText' | translate}}</span>
          </div>
        </div>

        <div class="routerContainerContent__supportActionContainer">
          <a href="{{environment.K_LATAUS.FAQ_URL}}" target="_blank">{{'instructions' | translate | uppercase}}</a>
          <a (click)="openOverlayDialog('SupportContactComponent')">{{'takeContact'|translate|uppercase}}</a>
        </div>
      </div>


      <!--Logout button on mobile-->
      <button class="routerContainerContent__logoutButton" tabindex="0" (click)="logOut()">
        <span>{{'logout'| translate | uppercase}}</span>
      </button>

    </div>
  </ng-container>
</ng-container>

<ng-template #notLoggedInTpl>
  <div class="routerContainerContent__container">
    <div class="routerContainerContent__header" (swipeup)="windowService.setRouteContainerOpenState(true)"
      (swipedown)="windowService.setRouteContainerOpenState(false)">
      <span class="title">{{ 'whyToRegisterKChargeTitle' | translate }}</span>
      <span class="info">{{ 'whyToRegisterKChargeInfo' | translate }}</span>
      <div class="border">//////////////////////////////////////////////////////////////////////////////////</div>
    </div>

    <div class="langSelectContainer">
        <div class="languages">
          <kc-language-selection-button [(ngModel)]="lang" [selected]="lang"
          value="fi" (ngModelChange)="onLangChange($event)"></kc-language-selection-button>
          <span class="dot">•</span>
          <kc-language-selection-button [(ngModel)]="lang" [selected]="lang"
          value="sv" (ngModelChange)="onLangChange($event)"></kc-language-selection-button>
          <span class="dot">•</span>
          <kc-language-selection-button [(ngModel)]="lang" [selected]="lang"
          value="en"(ngModelChange)="onLangChange($event)" ></kc-language-selection-button>
        </div>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <kc-loader></kc-loader>
</ng-template>

<ng-template #noDiscounts>
  <div class="noDiscountsWrapper">
    <div class="noDiscountsIcon">
      <img src="../../../assets/icons/png/icon-offer-gray.png">
    </div>
    <span class="textSmall">{{ 'noActiveDiscountsShort' | translate }}</span>
  </div>
</ng-template>


