<div class="overlayContainer__wrapper " [ngClass]="(requestStatus$ | async) !== 2 ? '--scrollable' : ''">
  <div class="overlayContainer__header">
    <span class="title">{{ 'transactionSummary' | translate }}</span>
    <div class="border" [ngClass]="isRoaming ? '--blue' : ''">
      //////////////////////////////////////////////////////////////////////////////////</div>
  </div>
  <ng-container>
    <div class="overlayContainer__contentWrapper">
      <div class="overlayContainer__contentTemplate">
        <div class="content">
          <img
            src="{{ isRoaming ? '../../../../assets/icons/png/icon-location@2x_blue.png' : '../../../../assets/icons/png/icon-location@2x.png' }}"
            alt="">
          <div class="textContainer">
            <span class="title">{{ 'chargePoint' | translate | uppercase }}</span>
            <span class="label">{{ transaction['chargePoint'] }}</span>
          </div>
        </div>
        <div class="content">
          <img
            src="{{ isRoaming ? '../../../../assets/icons/png/electronics_appliances@2x_blue.png' : '../../../../assets/icons/png/electronics_appliances@2x.png' }}"
            alt="">
          <div class="textContainer">
            <span class="title">{{ 'chargingSocket' | translate | uppercase }}</span>
            <span class="label">{{ transaction['chargeBoxUniqueId'] }} •
              {{ transaction['socketType'] | translate }}</span>
          </div>
        </div>
        <div class="content">
          <img
            src="{{ isRoaming ? '../../../../assets/icons/png/icon-info@2x_blue.png' : '../../../../assets/icons/png/icon-info@2x.png' }}"
            alt="">
          <div class="textContainer">
            <span class="title">{{ 'transactionInformation' | translate | uppercase }}</span>
            <span class="label">{{ transaction['timestampStart'] | localDate:'dd.MM.yyyy' }} • <span>{{transaction['timestampStart']
                    | localDate:'HH.mm'}} – {{transaction['timestampStop'] | localDate:'HH.mm'}}</span>
            </span>
            <span class="label">{{ transaction['energy'] / 1000 | localNumber:'1.1-1' }} <span>kWh</span></span>
            <span class="label">{{ hours }} <span>h</span>
              <span class="label"> {{ minutes }} <span>min</span>
              </span>
            </span>
          </div>
        </div>
        <div class="content">
          <img
            src="{{ isRoaming ? '../../../../assets/icons/png/icon-creditCard@2x_blue.png' : '../../../../assets/icons/png/icon-creditCard@2x.png' }}"
            alt="">
          <div class="textContainer">
            <span class="title">{{ 'transactionPrice' | translate | uppercase }}</span>
            <ng-container *ngIf="kIDSessionQuery.isLoggedIn(); else notRegisteredPrice">
              <div class="priceContainer" *ngIf="transaction?.transactionPrice">
                <ng-container *ngIf="!transaction.roaming; else roamingPrices">
                  <div class="price" *ngIf="transaction.transactionPrice.price.base">
                    <div class="start">
                      <span class="regularText">{{ 'startingPrice' | translate }}</span>
                    </div>
                    <div class="end">
                      <span class="label">{{  (transaction.transactionPrice.discount?.priceBeforeDiscount.base ? transaction.transactionPrice.discount?.priceBeforeDiscount.base : transaction.transactionPrice.price.base) / 100 | localCurrency }}</span>
                    </div>
                    <div class="start">
                      <span class="regularText" *ngIf="!hasSameVats() && transaction.transactionPrice.chargingPrice.baseTaxRate">({{ 'vat' | translate  }} {{ transaction.transactionPrice.chargingPrice.baseTaxRate }}%)</span>
                    </div>
                  </div>
                  <div class="price" *ngIf="transaction.transactionPrice.price.energy">
                    <div class="start">
                      <span class="regularText">{{ 'energy' | translate }}</span>
                    </div>
                    <div class="end">
                      <span class="label">{{ (transaction.transactionPrice.discount?.priceBeforeDiscount.energy ? transaction.transactionPrice.discount?.priceBeforeDiscount.energy : transaction.transactionPrice.price.energy) / 100 | localCurrency }}</span>
                    </div>
                    <div class="start">
                      <span class="regularText" *ngIf="!hasSameVats() && transaction.transactionPrice.chargingPrice.energyTaxRate">({{ 'vat' | translate  }} {{ transaction.transactionPrice.chargingPrice.energyTaxRate }}%)</span>
                    </div>
                  </div>
                  <div class="price" *ngIf="transaction.transactionPrice.price.duration">
                    <div class="start">
                      <span class="regularText">{{ 'time' | translate }}</span>
                    </div>
                    <div class="end">
                      <span class="label">{{ (transaction.transactionPrice.discount?.priceBeforeDiscount.duration ? transaction.transactionPrice.discount?.priceBeforeDiscount.duration : transaction.transactionPrice.price.duration) / 100 | localCurrency }}</span>
                    </div>
                    <div class="start">
                      <span class="regularText" *ngIf="!hasSameVats() && transaction.transactionPrice.chargingPrice.durationTaxRate">({{ 'vat' | translate  }} {{ transaction.transactionPrice.chargingPrice.durationTaxRate }}%)</span>
                    </div>
                  </div>
                  <ng-container *ngIf="transaction.transactionPrice['discount']">
                    <div class="price">
                      <div class="start">
                        <span class="regularText">{{ 'discount' | translate }}</span>
                      </div>
                      <div class="end">
                        <span
                          class="label">-{{ (transaction.transactionPrice.discount.totalBeforeDiscount - transaction.transactionPrice.price.total ) / 100 | localCurrency }}</span>
                      </div>
                      <div class="start">
                      </div>
                    </div>
                  </ng-container>
                  <div class="price">
                    <div class="start">
                      <span class="regularText">{{ 'total' | translate }}</span>
                    </div>
                    <div class="end">
                      <span class="label">{{ transaction.transactionPrice.price.total / 100 | localCurrency }}</span>
                    </div>
                    <div class="start">
                      <span class="regularText" *ngIf="hasSameVats()">({{ 'vat' | translate  }} {{ transaction.transactionPrice.chargingPrice.baseTaxRate }}%)</span>
                    </div>
                  </div>
                </ng-container>
                <span class="text" *ngIf="isInvoiceTransaction">{{ 'invoicingAgreement' | translate }}</span>
              </div>
              <!-- <span class="label">{{ transaction['price'] / 100 | localCurrency }} <span class="text">{{
                    'transactionPriceVat' | translate}}</span></span> -->
            </ng-container>
            <ng-template #notRegisteredPrice>
              <div class="price">
                <div class="start">
                  <span class="regularText">{{ 'startingPrice' | translate }}</span>
                </div>
                <div class="end">
                  <span class="label">{{ transaction.transactionPrice.price.base / 100 | localCurrency }}</span>
                </div>
                <div class="start">
                  <!-- <span class="regularText">({{ 'vat' | translate  }} {{ transaction.transactionPrice.chargingPrice.baseTaxRate }}%)</span> -->
                </div>
              </div>
              <div class="price">
                <div class="start">
                  <span class="regularText">{{ 'energy' | translate }}</span>
                </div>
                <div class="end">
                  <span class="label">{{ transaction.transactionPrice.price.energy / 100 | localCurrency }}</span>
                </div>
                <div class="start">
                  <!-- <span class="regularText">({{ 'vat' | translate  }} {{ transaction.transactionPrice.chargingPrice.energyTaxRate }}%)</span> -->
                </div>
              </div>
              <div class="price">
                <div class="start">
                  <span class="regularText">{{ 'time' | translate }}</span>
                </div>
                <div class="end">
                  <span class="label">{{ transaction.transactionPrice.price.duration / 100 | localCurrency }}</span>
                </div>
                <div class="start">
                  <!-- <span class="regularText">({{ 'vat' | translate  }} {{ transaction.transactionPrice.chargingPrice.durationTaxRate }}%)</span> -->
                </div>
              </div>
              <div class="price">
                <div class="start">
                  <span class="regularText">{{ 'total' | translate }}</span>
                </div>
                <div class="end">
                  <span class="label">{{ transaction.transactionPrice.price.total / 100 | localCurrency }}</span>
                </div>
                <div class="start">
                </div>
              </div>
            </ng-template>
          </div>
        </div>
        <ng-container *ngIf="transactionStop && !isRoaming">
          <div class="feedbackContainer">
            <span>{{ 'howDidChargingGo' | translate }}</span>
            <div class="thumbsContainer">
              <i class="fak fa-k-thumb-up" (click)="positiveFeedback()"></i>
              <div class="separator"></div>
              <i class="fak fa-k-thumb-down" (click)="negativeFeedback()"></i>
            </div>
          </div>
        </ng-container>
        <div class="overlayContainer__actionContainer --w100">
          <button class="overlayContainer__actionButton" [ngClass]="isRoaming ? '--blue': '--orange'" (click)="close()">
            <span>{{ 'close' | translate | uppercase }}</span>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #requestSendTpl>
    <div class="overlayContainer__contentWrapper">
      <div class="overlayContainer__contentTemplate">
        <div class="overlayContainer__contentCentered">
          <div class="iconContainer">
            <div class="-white">
              <img src="../../../../../assets/icons/png/icon-file@3x.png" alt="">
            </div>
          </div>
          <div>
            <img src="../../../../../assets/icons/png/icon-tick@2x.png" alt="">
          </div>
          <div class="overlayContainer__header">
            <span class="title">{{ 'receiptOrdered' | translate }}</span>
          </div>
        </div>
        <div class="overlayContainer__actionContainer --w100">
          <button class="overlayContainer__actionButton" [ngClass]="isRoaming ? '--blue' : '--white'" (click)="close()">
            <span>{{ 'close' | translate | uppercase}}</span>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #roamingPrices>
  <div class="price">
    <div class="start">
      <span class="regularText">{{ 'total' | translate }}</span>
    </div>
    <div class="end">
      <span class="label">{{ transaction.transactionPrice.price.total / 100 | localCurrency }}</span>
    </div>
    <div class="start">
      <span class="regularText">({{ 'vat' | translate  }} {{transaction.transactionPrice.price.vat ? transaction.transactionPrice.price.vat : '-'}} %)</span>
    </div>
  </div>
</ng-template>
