<div class="overlayContainer__wrapper" [ngClass]="(requestStatus$ | async) !== 2 ? '--scrollable' : ''">
  <div class="overlayContainer__header">
    <span class="title">{{ (!isCancelContract ? 'takeContact' : 'contractCancellation') | translate }}</span>
  </div>
  <ng-container *ngIf="(requestStatus$ | async) !== 2; else requestSendTpl">
    <div class="overlayContainer__contentWrapper">
      <form class="overlayContainer__contentTemplate" [formGroup]="contactSupportForm"
        (ngSubmit)="sendSupportRequest(contactSupportForm)">

        <ng-container *ngIf="!isCancelContract">
          <ng-container *ngTemplateOutlet="contactSupportDiv"></ng-container>
        </ng-container>
        <ng-container *ngIf="isCancelContract">
          <ng-container *ngTemplateOutlet="cancelContractDiv"></ng-container>
        </ng-container>

        <ng-container *ngIf="supportWizardPage == 1; else backButton">
          <div class="overlayContainer__actionContainer">
            <button class="overlayContainer__actionButton --white --left" (click)="close()">
              <span>{{ 'cancel' | translate | uppercase}}</span>
            </button>
            <button type="submit" class="overlayContainer__actionButton --orange --right"
              [disabled]="!contactSupportForm.valid || (requestStatus$ | async) === 1 || (isCancelContract && contactSupportForm.value.reason !== 'unsubscribeService')">
              <ng-container *ngIf="(requestStatus$ | async) === 1; else sendTpl">
                <div class="spinner --small">
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                </div>
                <span>{{ 'sending' | translate | uppercase}}</span>
              </ng-container>
              <ng-template #sendTpl>
                <span>{{ 'send' | translate | uppercase}}</span>
              </ng-template>
            </button>
          </div>
        </ng-container>


        <!-- CONTACT SUPPORT FORM -->
        <ng-template #contactSupportDiv>
          <ng-container [ngSwitch]="supportWizardPage">
            <ng-container *ngSwitchCase="1">
              <div class="overlayContainer__contentTemplateLabel">
                <span>{{ 'contactWithCall' | translate | uppercase }}</span>
              </div>
              <div class="overlayContainer__textContainer">
                <a>
                  <img src="../../../../assets/icons/png/icon-phone@2x.png"
                    alt="">{{ 'supportCallNumber' | translate }}</a>
                <label for="" class="callPriceLabel">{{ 'supportCallPrice' | translate }}</label>
              </div>
              <div class="overlayContainer__contentTemplateLabel">
                <span>{{ 'contactWithForm' | translate | uppercase }}</span>
              </div>
              <div class="inputContainer cancelContract" (click)="switchPage(2)">
                <span>{{ contactSupportForm.get('reason').value ? (contactSupportForm.get('reason').value | translate) : ('chooseCategory' | translate) + '*' }}</span>
                <img src="../../../assets/icons/png/icon-arrow-s-right@2x.png" alt="">
              </div>
              <ng-container *ngIf="contactSupportForm.get('reason').value === 'suggestLocation' && contactSupportForm.get('city').value !== ''">
                <div class="inputContainer cancelContract" (click)="switchPage(2)">
                  <span>{{ contactSupportForm.get('city').value | translate }}</span>
                  <img src="../../../assets/icons/png/icon-arrow-s-right@2x.png" alt="">
                </div>
              </ng-container>
              <ng-container *ngIf="contactSupportForm.get('reason').value === 'chargerError' && contactSupportForm.get('charger').value !== ''">
                <div class="inputContainer cancelContract" (click)="switchPage(2)">
                  <span>{{ contactSupportForm.get('chargePoint').value | translate }}</span>
                  <img src="../../../assets/icons/png/icon-arrow-s-right@2x.png" alt="">
                </div>
                <div class="inputContainer cancelContract" (click)="switchPage(2)">
                  <span>{{ contactSupportForm.get('charger').value }}</span>
                  <img src="../../../assets/icons/png/icon-arrow-s-right@2x.png" alt="">
                </div>
              </ng-container>
              <ng-container *ngIf="contactSupportForm.get('reason').value === 'chargerError'">
                <div class="inputContainer">
                  <label for="car">{{ 'car' | translate }}</label>
                  <input class="car" type="text" id="car" placeholder="{{ 'carMakeAndModel' | translate }}" formControlName="car">
                </div>
              </ng-container>
              <div class="inputContainer">
                <label for="description">{{ 'description' | translate }}</label>
                <textarea class="textarea" rows="2" cols="20" wrap="hard" id="description"
                  formControlName="description"></textarea>
              </div>
              <div class="radioGroup">
                <div class="inputContainer">
                  <label for="reason">{{ 'wantToBeContacted' | translate}}</label>
                  <input type="checkbox" formControlName="wantToBeContacted">
                  <span class="radioButton"></span>
                </div>
              </div>
              <div class="inputContainer cancelContract" (click)="cancelContract()">
                <span>{{ 'customerContractCancellation' | translate }}</span>
                <img src="../../../assets/icons/png/icon-arrow-s-right@2x.png" alt="">
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="2">
              <div class="overlayContainer__contentTemplateLabel">
                <span>{{ 'chooseCategory' | translate | uppercase }}</span>
              </div>
              <div class="radioGroup">
                <div class="inputContainer" *ngFor="let type of supportContactTypes">
                  <label for="reason">{{type.type | translate}}</label>
                  <input type="radio" formControlName="reason" value="{{ type.type }}">
                  <span class="radioButton"></span>
                </div>
              </div>
              <ng-container
                *ngIf="contactSupportForm.get('reason').value == 'chargerError' || contactSupportForm.get('reason').value == 'suggestLocation'">
                <div class="overlayContainer__contentTemplateLabel">
                  <span>{{ 'additionalInformation' | translate | uppercase }}</span>
                </div>
                <div class="inputContainer">
                  <ng-container [ngSwitch]="contactSupportForm.get('reason').value">
                    <ng-container *ngSwitchCase="'chargerError'">
                      <label for="charger" class="topMarginLabel" style="margin-top: 10px;">{{ 'chargePoint' | translate }}</label>
                      <input type="text" formControlName="chargePoint" id="charger">
                      <ng-container *ngIf="possibleChargePoints.length > 0 && !selectedChargePoint">
                        <div class="autofillItemsContainer">
                          <ng-container *ngFor="let cp of possibleChargePoints">
                            <div class="autofillItem" (click)="onSelectChargePoint(cp)">
                              <span>{{cp.name}}</span>
                            </div>
                          </ng-container>
                        </div>
                      </ng-container>
                      <label for="charger">{{ 'charger' | translate }}</label>
                      <select #chargers formControlName="charger" (change)="selectCharger(chargers.value)">
                        <ng-container *ngFor="let charger of chargerOptions">
                          <option value="{{charger.uniqueId ? charger.uniqueId : charger.EvseID}}">
                            {{charger.uniqueId ? charger.uniqueId : charger.EvseID}}
                          </option>
                        </ng-container>
                      </select>

                      <div class="overlayContainer__textContainer">
                        <div class="infoContainer">
                          <img src="../../../../../assets/icons/png/icon-info@2x.png" alt="">
                          <div>
                            <span>{{ 'reportChargerProblemInfo' | translate}}</span>
                            <span>{{ 'supportCallNumber' | translate}}</span>
                            <span>{{ 'supportCallPrice' | translate}}</span>
                          </div>
                        </div>
                      </div>
                      <!-- <input type="text" class="autofillable" formControlName="charger" id="charger">
                      <ng-container *ngIf="chargerOptions.length > 0 && (selectedChargePoint && !chargerSelected) && contactSupportForm.get('charger').value.length > 0">
                        <div class="autofillItemsContainer">
                          <ng-container *ngFor="let charger of chargerOptions">
                            <div class="autofillItem" (click)="selectCharger(charger)">
                              <span>{{charger.uniqueId ? charger.uniqueId : charger.EvseID}}</span>
                            </div>
                          </ng-container>
                        </div>
                      </ng-container> -->
                      <!-- <ng-container *ngIf="chargerErrorText">
                        <div class="labelText">
                          <span>{{ 'chargerErrorInfoText' | translate }}</span>
                        </div>
                      </ng-container> -->
                    </ng-container>
                    <ng-container *ngSwitchCase="'suggestLocation'">
                      <label for="charger">{{ 'city' | translate }}</label>
                      <input type="text" class="autofillable" formControlName="city" id="city">
                      <ng-container *ngIf="matchingCities.length > 0 && !itemSelected">
                        <div class="autofillItemsContainer">
                          <ng-container *ngFor="let city of matchingCities">
                            <div class="autofillItem" (click)="selectCity(city.city)">
                              <span>{{city.city}}</span>
                            </div>
                          </ng-container>
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-template>

        <!-- CANCEL CONTRACT FORM -->
        <ng-template #cancelContractDiv>
          <div class="overlayContainer__textContainer">
            <div class="cancelContractIcon">
              <img src="../../../assets/icons/png/icon-profile@2x.png" alt="">
            </div>
          </div>
          <div class="overlayContainer__header">
            <span class="title">{{ 'contractCancellationHeader' | translate }}</span>
            <div class="infoAndLink">
              <span class="info" style="margin-right: 4px">{{ 'contractCancellationText1' | translate }}</span>
              <a href="https://plussa.fi/k-plussa/k-tunnus" target="_blank">https://plussa.fi/k-plussa/k-tunnus.</a>
            </div>
            <span class="info">{{ 'contractCancellationText2' | translate }}</span>
            <span class="info">{{ 'contractCancellationText3' | translate }}</span>
          </div>
          <div class="radioGroup">
            <div class="inputContainer">
              <label for="reason">{{ 'agreeToCancelContract' | translate}}</label>
              <input type="radio" formControlName="reason" value="unsubscribeService">
              <span class="radioButton"></span>
            </div>
          </div>
          <div class="inputContainer">
            <textarea class="textarea" rows="2" cols="20" wrap="hard" id="description" formControlName="description"
              placeholder="{{ 'writeFeedbackHere' | translate }}"></textarea>
          </div>
        </ng-template>
      </form>
    </div>
  </ng-container>

  <ng-template #requestSendTpl>
    <ng-container *ngIf="!isCancelContract; else cancelContractReceivedDiv">
      <div class="overlayContainer__contentWrapper">
        <div class="overlayContainer__contentTemplate">
          <div class="successfulIcon">
            <img src="../../../../../assets/icons/png/icon-tick@2x.png" alt="">
          </div>
          <ng-container *ngIf="!contactSupportForm.get('reason').value === 'suggestLocation'; else requestSendLocationTpl">
            <div class="overlayContainer__header">
              <span class="title">{{ 'supportRequestSendTitle' | translate }}</span>
              <span class="info">{{ 'supportRequestSendInfo' | translate }}</span>
            </div>
          </ng-container>
          <div class="overlayContainer__actionContainer --w100">
            <button class="overlayContainer__actionButton --white" (click)="close()">
              <span>{{ 'close' | translate | uppercase}}</span>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-template>
</div>

<ng-template #cancelContractReceivedDiv>
  <div class="overlayContainer__textContainer">
    <div class="cancelContractIcon">
      <img src="../../../assets/icons/png/icon-profile@2x.png" alt="">
    </div>
  </div>
  <div class="overlayContainer__header">
    <span class="title">{{ 'contractCancellationReceivedHeader' | translate }}</span>
    <span class="info">{{ 'contractCancellationReceivedText1' | translate }}</span>
    <span class="info">{{ 'contractCancellationReceivedText2' | translate }}</span>
    <span class="info">{{ 'contractCancellationReceivedText3' | translate }}</span>
  </div>
  <div class="overlayContainer__actionContainer --w100">
    <button class="overlayContainer__actionButton --white" (click)="close()">
      <span>{{ 'close' | translate | uppercase}}</span>
    </button>
  </div>
</ng-template>

<ng-template #backButton>
  <div class="overlayContainer__actionContainer --w100">
    <button class="overlayContainer__actionButton --orange" [disabled]="isDoneButtonDisabled()" (click)="switchPage(1)">
      <span>{{ 'done' | translate | uppercase}}</span>
    </button>
  </div>
</ng-template>

<ng-template #requestSendLocationTpl>
  <div class="overlayContainer__header">
    <span class="title">{{ 'supportRequestSendTitle' | translate }}</span>
    <span class="info">{{ 'supportRequestSendInfo' | translate }}</span>
  </div>
</ng-template>
