import { Component, OnInit, Inject, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { PORTAL_DATA } from 'src/app/overlay-dialog/overlay-dialog.storage';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { requestStatus } from 'src/app/helpers/requestStatuses';
import { KChargeProfileService } from 'src/app/k-charge-profile/k-charge-profile.service';
import { KIDSessionQuery } from 'src/app/k-id-session/k-id-session.query';
import { InvoiceTransactionService } from '../state/invoice-transaction.service';
import { OverlayDialogService } from 'src/app/overlay-dialog/overlay-dialog.service';
import { Transaction } from '../state/transaction.model';
import TransactionFeedback, { TransactionFeedbackService, TransactionFeedbackType } from 'src/app/services/transaction-feedback.service';
import { ConverterHelper } from 'src/app/helpers/converter.helper';

@Component({
  selector: 'kc-transaction-summary',
  templateUrl: './transaction-summary.component.html',
  styleUrls: ['./transaction-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransactionSummaryComponent implements OnInit {

  isRoaming = true;
  transaction: Transaction;
  transactionStop: boolean;
  hours: number;
  minutes: number;
  requestStatus$ = new BehaviorSubject<requestStatus>(requestStatus.PENDING);
  isInvoiceTransaction: boolean;
  @Output() openFeedbackEvent = new EventEmitter<boolean>();

  constructor(@Inject(PORTAL_DATA) public data,
              public kIDSessionQuery: KIDSessionQuery,
              private invoiceTransactionService: InvoiceTransactionService,
              private cdr: ChangeDetectorRef,
              private transactionFeedbackService: TransactionFeedbackService) { }

  ngOnInit() {
    this.transaction = this.data['data']['transaction'];
    this.transactionStop = this.data['data']['transactionStop'];

    if (!this.transaction) {
      this.close();
    }

    this.isRoaming = this.transaction['roaming'];
    this.formatChargingTime(this.transaction.timestampStart, this.transaction.timestampStop);

    if ((this.isRoaming && this.transaction['transactionId']) || this.transaction['_id']) {
      this.invoiceTransactionService.getInvoiceTransaction(this.isRoaming ? this.transaction['transactionId'] : this.transaction['_id'], this.isRoaming).subscribe(res => {
        if (res.length > 0) {
          this.isInvoiceTransaction = true;
          this.cdr.detectChanges();
        }
      });
    }
  }

  formatChargingTime(timeStart: string, timeStop: string) {
    const ms = moment(timeStop).diff(moment(timeStart));
    const d = moment.duration(ms);
    this.hours = Math.floor(d.asHours());
    this.minutes = +moment.utc(ms).format('mm');
  }

  getAmountDeducted(price: number, percentage: number) {
    return Math.round((percentage / 100) * price);
  }

  getReducedPrice(price: number, percentage: number) {
    return price - this.getAmountDeducted(price, percentage);
  }

  positiveFeedback() {
    const transactionFeedback: TransactionFeedback = {
      chargePointId: this.transaction.chargePointId,
      chargePointGroupId: this.transaction.chargePointGroupId,
      chargeBoxId: this.transaction.chargeBoxId,
      chargeBoxGroupId: this.transaction.chargeBoxGroupId,
      transactionId: this.transaction.id,
      type: TransactionFeedbackType.POSITIVE,
      powerType: ConverterHelper.convertPowerType(this.transaction.powerType),
    }

    this.transactionFeedbackService.sendTransactionFeedback(transactionFeedback).subscribe();
    this.close();
  }

  negativeFeedback() {
    this.openFeedbackEvent.emit(true);
    this.close();
  }

  close() {
    this.data.close();
  }

  /**
  * Check whether all VATs are the same.
  */
  hasSameVats() {
    const baseTax = this.transaction.transactionPrice.chargingPrice.baseTaxRate;
    const energyTax = this.transaction.transactionPrice.chargingPrice.energyTaxRate;
    const durationTax = this.transaction.transactionPrice.chargingPrice.durationTaxRate;


    if (baseTax && baseTax == energyTax && baseTax == durationTax) {
      return true;
    }

    return false;

  }
}

